import React from "react"
const owendCube = [{id:1,name:"null"},{id:2,name:"null"}];

const Complexlist = ({ owendCube }) => (
  <div>
    <div>Nr of cubess owend : {owendCube.length}</div>
    <ul>
      {owendCube.map(item => (
        <li key={item.id}>
          <div>ID: {item.id} - Name: {item.name}</div>
        </li>
      ))}
    </ul>
  </div>
  )

export default Complexlist;