import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import "./Dashboard.css";
import { auth, db, logout } from "./firebase";
import { query, collection, getDocs, where , orderBy } from "firebase/firestore";
import Complexlist from "./CubeOwend";
function Dashboard() {
  const [user, loading, error] = useAuthState(auth);
  const [name, setName] = useState("");
  const navigate = useNavigate();
  const [owendCube, setowendCube]=useState([])
 

  const fetchUserName = async () => {
    try {
      const q = query(collection(db, "users"), where("uid", "==", user?.uid));
      const doc = await getDocs(q);
      const data = doc.docs[0].data();
      setName(data.name);
    } catch (err) {
      console.error(err);
      alert("An error occured while fetching user data");
    }
  };
  const FerchCubeData = async () => {
    setowendCube([]) // reset list 
    try{
      const CubeQuery = query(collection(db,'cubes'), where("owner", "==", user?.uid)); //,orderBy("id")
      const QueryResult = await getDocs(CubeQuery);
      setowendCube([]) // reset list 
      if (QueryResult.docs.length > 0) {
        QueryResult.docs.forEach(cube => {
          console.log('Document data:', cube.data());
          setowendCube( owendCube2 => [...owendCube2,cube.data()])
           
        });  
      } 
    }
    catch (err) {
      console.error(err);
      alert("An error occured while fetching Cube data");
    }
  }


 

  useEffect(() => {
    if (loading) return;
    if (!user) return navigate("/");
    fetchUserName();
    FerchCubeData();
    }, [user, loading]
    ); 
  return (
    <div className="dashboard">
       <div className="dashboard__container">
        Logged in as
         <div>{name}</div>
         <div>{user?.email}</div>
         <div>{user?.uid}</div>
         <br></br>
         <Complexlist owendCube={owendCube}/>
         {console.log(owendCube)}
         <button className="dashboard__btn" onClick={logout}>
          Logout
         </button>
       </div>
     </div>
  );
}
export default Dashboard;